import { useEffect, useState } from 'react';
import { useListGuestsWithParams, useListGuestsWithParamsCsv, useUserDetail } from 'hooks/queries';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useForm } from 'react-hook-form';
import { t } from 'i18next';
import FileSaver from 'file-saver';

import dayjs from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export const useGuests = (queryParams) => {
  // React query
  let data;
  let isLoading = true;
  let isFetched;
  let refetch;

  // The guest editor is mixed with the guest set query, so if you specify an id you change the query.
  if (queryParams.id) {
    const query = useUserDetail(queryParams.id);
    data = query.data ? { rows: [query.data] } : null;
    isLoading = query.isLoading;
    isFetched = query.isFetched;
    refetch = query.refetch;
  } else {
    const query = useListGuestsWithParams(queryParams);
    data = query.data;
    isLoading = query.isLoading;
    isFetched = query.isFetched;
    refetch = query.refetch;
  }
  const { setStateAlert, stateAlert } = useAlert();
  const queryClient = useQueryClient();
  const [menteeIdDetailsSelected, setMenteeIdDetailsSelected] = useState(null);

  // For edit guests
  const { handleSubmit, control, watch, setValue, reset } = useForm({
    mode: 'all',
    defaultValues: {
      scopes: [],
      role: '',
      firstname: '',
      lastname: '',
      email: '',
      idNumber: '',
      gender: '',
      bio: '',
      expectations: '',
      picture: '',
      timeZone: '',
      city: '',
      languagesSpoken: [],
      phoneNumber: '',
      linkedinUrl: '',
      seniorityLevelId: '',
      deletePicture: false,
      managerEmail: '',
      departmentId: '',
    },
  });

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [editingGuest, setEditingGuest] = useState(null);
  const [source, setSource] = useState('');

  const updateList = (users) =>
    users.reduce((acc, currentItem) => {
      const modifiedItem = {
        ...currentItem,
        name: currentItem.firstname + ' ' + currentItem.lastname,
        user: {
          name: currentItem.firstname + ' ' + currentItem.lastname,
          title: currentItem.title,
          picture: currentItem.picture,
        },
        scopeName: currentItem.scopeName != null ? currentItem.scopeName : '',
        type: currentItem.internalCoach ? 'INTERNAL' : 'EXTERNAL',
        createdAt: dayjs(currentItem.createdAt).fromNow(),
      };
      acc.push(modifiedItem);
      return acc;
    }, []);

  const guests = data ? updateList(data.rows) : [];

  const mutationDelete = useMutation(api.Guests.delete, {
    onSuccess: async (_data, id) => {
      refetch();
      setStateAlert({ open: true, message: t('user_deleted_successfully') });
    },
    onError: (error) => {
      setStateAlert({ open: true, message: `${error.response.data.error}` });
    },
  });

  const mutationUserDelete = useMutation(api.Users.delete, {
    onSuccess: async (_data, id) => {
      refetch();
      setStateAlert({ open: true, message: t('user_deleted_successfully') });
    },
    onError: (error) => {
      setStateAlert({ open: true, message: `${error.response.data.error}` });
    },
  });

  const handleDeleteGuest = ({ id }) => {
    return mutationDelete.mutate(id);
  };

  const handleDeleteGuestBackoffice = ({ id, source }) => {
    if (source == 'User') {
      return mutationUserDelete.mutate(id);
    } else {
      return mutationDelete.mutate(id);
    }
  };

  useEffect(() => {
    if (editingGuest) {
      const scopeName = editingGuest.scopeName || editingGuest?.scope?.name;
      const userScope = editingGuest.scopeId || editingGuest?.scope?.id;

      setSource(editingGuest.source || 'User');

      setValue('firstname', editingGuest.firstname);
      setValue('lastname', editingGuest.lastname);
      setValue('email', editingGuest.email);
      setValue('idNumber', editingGuest.idNumber ? editingGuest.idNumber : '');
      setValue('userScope', userScope);
      setValue('scopeName', scopeName), setValue('gender', editingGuest.gender);
      setValue('bio', editingGuest.bio || '');
      setValue('expectations', editingGuest.expectations || '');
      setValue('picture', editingGuest.picture || '');
      setValue('timeZone', editingGuest.timeZone);
      setValue('city', editingGuest.city || '');
      setValue('languagesSpoken', editingGuest.languages || []);
      setValue('phoneNumber', editingGuest.phoneNumber || '');
      setValue('linkedinUrl', editingGuest.linkedinUrl || '');
      setValue('seniorityLevelId', editingGuest.seniorityLevelId || '');
      setValue('managerEmail', editingGuest.managerEmail || '');
      setValue('departmentId', editingGuest.departmentId || '');
      setValue('deletePicture', false);
    }
  }, [editingGuest]);

  useEffect(() => {
    if (queryParams.id && guests?.length === 1) {
      setMenteeIdDetailsSelected(queryParams.id);
    }
  }, [queryParams.id, guests]);

  useEffect(() => {
    setEditingGuest(guests.find(({ id }) => id === menteeIdDetailsSelected));
  }, [menteeIdDetailsSelected]);

  const mutationEdit = useMutation(source === 'User' ? api.Users.edit : api.Guests.edit, {
    onSuccess: async () => {
      await queryClient.invalidateQueries();

      setMenteeIdDetailsSelected(null);
      setIsOpenEditModal(false);

      setStateAlert({
        open: true,
        message: source === 'User' ? 'User successfully edited' : 'Guest successfully edited',
      });

      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });
  const onEdit = handleSubmit(async (item) => {
    const data = { ...item, companyId: queryParams.companyId };

    if (item.userScope) {
      data.scopeId = item.userScope;
    }

    if (item.languagesSpoken) {
      data.languages = item.languagesSpoken;
    }

    if (!item.gender) {
      delete data.gender;
    }

    if (!item.seniorityLevelId) {
      delete data.seniorityLevelId;
    }

    if (!item.departmentId) {
      delete data.departmentId;
    }

    if (!!data.picture && typeof data.picture !== 'string') {
      const { url } = await api.Users.upload(data.picture);
      data.picture = process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key;
    } else {
      delete data.picture;
    }

    if (data.deletePicture) {
      data.picture = '';
    }

    delete data.deletePicture;

    mutationEdit.mutate({ id: editingGuest.id, data });
  });

  const mutationValidateGuest = useMutation(api.Guests.rhValidation, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-list-guests-params']);
      setStateAlert({
        open: true,
        message: t('validation-self-registration'),
      });
      setIsOpenEditModal(false);
      setMenteeIdDetailsSelected(null);
      reset();
    },
    onError: (error) => {
      queryClient.invalidateQueries(['get-list-guests-params']);
      setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' });
      setIsOpenEditModal(false);
      reset();
    },
  });

  const onValidateGuestSelfRegistration = (guestId) => {
    mutationValidateGuest.mutate({ id: guestId });
  };

  const deleteImage = async () => {
    setValue('deletePicture', true);
    onEdit();
  };

  return {
    menteeIdDetailsSelected,
    guests,
    totalPages: data && data.totalPages,
    totalItems: data && data.totalItems,
    isLoading,
    isFetched,
    handleDeleteGuest,
    handleDeleteGuestBackoffice,
    reset,
    watch,
    control,
    isOpenEditModal,
    setIsOpenEditModal,
    setEditingGuest,
    onEdit,
    onValidateGuestSelfRegistration,
    companyId: queryParams.companyId,
    isEditLoading: mutationEdit.isLoading,
    setMenteeIdDetailsSelected,
    actions: { watch, submitForm: onEdit, deleteImage },
  };
};

export const useGuestsCsv = () => {
  const [authorizeExportDataDownload, setAuthorizeExportDataDownload] = useState(false);

  const {
    data: dataCsv,
    refetch: refetchCsv,
    isLoading: isLoadingExportData,
    isRefetching,
  } = useListGuestsWithParamsCsv({ type: '!RH_Validation', csv: true });

  useEffect(() => {
    if (dataCsv && authorizeExportDataDownload && !isLoadingExportData && !isRefetching) {
      const blob = new Blob([dataCsv], { type: 'text/csv' });
      FileSaver.saveAs(blob, `users-coachees.csv`);
      setAuthorizeExportDataDownload(false);
    }
  }, [dataCsv, authorizeExportDataDownload, isLoadingExportData, isRefetching]);

  const downloadCsv = () => {
    setAuthorizeExportDataDownload(false);

    // Waits for inite params to be updated in query hook
    setTimeout(() => {
      refetchCsv();
      setAuthorizeExportDataDownload(true);
    }, 100);
  };

  return {
    downloadCsv,
  };
};
